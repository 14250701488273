import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react component for creating beautiful carousel
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.jsx";
import Plx from 'react-plx';
import Globales from "utils/Globales";
import ModalArtista from '../../Generales/Modales/ModalTiendaArtista';
import UsuarioBlanco from "assets/img/faces/UsuarioBlanco1.png";
import { Link } from "react-router-dom";
import Loader from 'react-loader-spinner';
import '../../../Styles/GridStyle.css';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridItem.jsx";
import CircularProgress from '@material-ui/core/CircularProgress';

// import 'bootstrap/dist/css/bootstrap.min.css';

class Seccion3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Cargando: false,
      colorSelect: "0",
      sizeSelect: "0",
      Artistas: [],
      paginas: [],
      VerModalArtista: false,
      DatosArtista: {},
    };
    this.EventoModal = this.EventoModal.bind(this)
  }

  componentDidMount() {
    this.ObtenerArtistas();
  }

  ObtenerArtistas() {
    this.setState({ Cargando: true })
    fetch(Globales.Url + 'TiendaArtistas/TiendaArtistas/ObtenerAristasDisenosMasVendidos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({ Fin: 6, Todos: false })

    }
    ).then(res => res.json())
      .then(data => {
        //console.log("Estos son los artistas....")
        //console.log(data)
        if (data.error) {

        } else {
          this.setState({
            Artistas: data.data,
            Cargando: false
          });
        }
      })
  }

  EventoModal(e) {
    this.setState({
      VerModalArtista: false
    });
  }

  render() {
    const { classes, ...rest } = this.props;
    const parallaxData = [
      {
        start: 1000,
        // end: 3800,
        end: 4000,
        properties: [
          {
            startValue: 10,//Donde inicia
            endValue: -20, //Donde termina
            property: 'translateY',
            unit: '%',
          }
        ]
      }
    ];

    return (
      <div className="cd-section" {...rest}>
        {/* <Plx
          className='MyAwesomeParallax'
          parallaxData={parallaxData}
        > */}
        <div className="container" style={{ width: '100%' }}>
          <div className="contenedor">
            {/* <h2 className={classNames(classes.title, classes.textCenter)}> */}
            <h2 className={classNames(classes.title, classes.textCenter)}>
              <span> Artistas </span>
            </h2>
          </div>
          <br></br>
          <GridContainer style={{ marginTop: '40px' }}>
            {
              this.state.Cargando == false ?
                <div className="row">
                  {
                    this.state.Artistas.map(artista =>
                      <GridItem className="dvContenido" md={4} style={{ height: '150px' }}>
                        <div className="artista-card" onClick={() => window.location.href = `#/Tienda/${artista._id}`}>
                          <div style={{ width: "30%", marginLeft: '25px', marginRight: '25px' }}>
                            <img src={(artista.LogoTienda == "") ? UsuarioBlanco : "data:image/png;base64," + artista.LogoImg}
                              style={{ width: '80px', height: '80px', objectFit: 'cover' }} />
                          </div>
                          <div style={{ width: "70%", marginRight: '25px' }}>
                            <p style={{ fontSize: '18px' }}><b>{artista.Nombre}</b></p>
                            <p>{artista.totalVendidos} Diseños vendidos</p>
                          </div>
                        </div>
                      </GridItem>
                    )
                  }
                </div>
                : <div className="row" style={{ color: "white" }}>
                  <center>
                    <h3>
                      Cargando artistas...
                      <CircularProgress size={35} thickness={5} style={{ color: "white" }} />
                    </h3>
                  </center>
                </div>
            }
          </GridContainer>
          <div className="contenedor" style={{ marginTop: '40px' }}>
            <Link to="/Artistas">
              <h4 className="btn">
                <span> Ver todos los artistas </span>
              </h4>
            </Link>
          </div>
        </div>

        {/* </Plx> */}
        {
          this.state.VerModalArtista == true ?
            <ModalArtista
              verModal={this.state.VerModalArtista}
              eventoModal={this.EventoModal}
              DatosArtista={this.state.DatosArtista}

            />
            : null
        }
      </div >
    );


  }
}

export default withStyles(productStyle)(Seccion3);
