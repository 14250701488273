
// @material-ui/core components

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import UsuarioBlanco from "assets/img/faces/UsuarioBlanco1.png";


import DialogActions from "@material-ui/core/DialogActions";
import { Slide } from "react-slideshow-image";

class ModalDomicilio extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};

    this.PropiedadesVisor = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: true,
      autoplay: false,
      onChange: (oldIndex, newIndex) => { }
    };
    this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventoModal("", false);
    }
  }

  componentDidMount() {
    //console.log(this.props)
    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillMount() {}
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        {/* SIGNUP MODAL START */}
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + " " + classes.modalSignup
          }}
          id="mdlConfiguracionProducto"
          open={this.props.verModal}
          // TransitionComponent={Transition}
          keepMounted
          aria-labelledby="signup-modal-slide-title"
          aria-describedby="signup-modal-slide-description"
        >
          <Card plain className={classes.modalSignupCard}>
            <DialogTitle
              id="signup-modal-slide-title"
              disableTypography
              className={classes.modalHeader}
            >
              <Button
                simple
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                onClick={() => this.props.eventoModal("", false)}
              >
                {" "}
                <Close className={classes.modalClose} />
              </Button>
            </DialogTitle>
            <DialogContent id="signup-modal-slide-description" className={classes.modalBody}>
            <GridContainer>
                <GridItem lg={6} md={6} sm={12} xs={12}>
                  <GridContainer justify="center">
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <GridContainer justify="center">
                        <GridItem lg={12} md={12} sm={12} xs={12}>
                          <br />
                          <center>
                            <img
                              width="150px"
                              src={
                                this.props.DatosArtista.LogoTienda.length == 0
                                  ? UsuarioBlanco
                                  : "data:image/png;base64," +
                                    this.props.DatosArtista.LogoImg
                              }
                            />{" "}
                            <br />
                          </center>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                    <GridItem lg={12} md={12} sm={12} xs={12}>
                      <GridContainer justify="center">
                        <GridItem lg={12} md={12} sm={12} xs={12}>
                          <center>
                            <h3>
                              {this.props.DatosArtista.NombreCliente[0]} <br />
                            </h3>
                            <h4 onClick={() =>    window.location.href = `#/Tienda/${this.props.DatosArtista._id}`} style={{textDecoration:'underline', cursor:'pointer'}}> 
                            Visitar tienda 
                            </h4>
                           </center>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem lg={6} md={6} sm={12} xs={12}>
                  <div className="slide-container">
                    <Slide defaultIndex={0} ref={this.myRef} {...this.PropiedadesVisor}>
                        {
                        this.props.DatosArtista.Productos.map(p =>
                           <div style={{ width: '420px' }} className="each-fade">
                            <div className="image-container">
                                <Card>
                                  <CardHeader color="primary">
                                      <center>
                                         {p.nombre}
                                      </center>
                                   </CardHeader>
                                   <CardBody style={{padding:"15px"}}>
                                       <center>
                                           <img width="220px" height="220px" src={"data:image/png;base64," + p.ImagenPro} /> 
                                        </center>
                                    </CardBody>
                                </Card>
                            </div>
                          </div>
                        )
                        }
                    </Slide>
                  </div>
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions className={classes.modalFooter} />
          </Card>
        </Dialog>
        {/* SIGNUP MODAL END */}
      </div>
    );
  }
}

export default withStyles(modalStyle)(ModalDomicilio);
