import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react component for creating beautiful carousel
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.jsx";
import Globales from "utils/Globales";
import box from "assets/img/box.png";
import elige from "assets/img/elige.png";
import personaliza from "assets/img/personaliza.png";
import confirma from "assets/img/confirma.png";
import recive from "assets/img/recibe.png";
import '../../../Styles/GridStyle.css';

class Seccion6 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Disenos: [],
      colorSelect: "0",
      sizeSelect: "0",
      botonCarrito: false
    };
    global.botonCar = false
  }


  componentDidMount() {
    this.ObtenerDisenosMasVendidos()
  }


  ObtenerDisenosMasVendidos() {
    fetch(Globales.Url + 'Reportes/ReporteDeProductos/ObtenerDesenosMasVendidos', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      },
      body: JSON.stringify({ FechaFinal: "" })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log("🍺 / file: ReporteDeProductos.jsx / line 90 / data", data)

        if (!data.error) {
          this.setState({
            Disenos: data.data
          })
        }
      });
    // this.setState({ Disenos:[{nombre:"Prueba 1", RutaImagen: Globales.Url+"Seccion1/jersey.jpg" },{nombre:"Prueba 2", RutaImagen: Globales.Url+"Seccion1/tshirt.jpg"}] });
  }

  addCarrito(e) {
    //console.log('prueba');
    global.botonCar = true
  }

  botonOn() {
    global.botonCar = true;
  }

  botonOff() {
    global.botonCar = false;
  }

  ObtenerProductosPorDiseno(Producto, idCategoria) {
    /**obtener la info del abanico de productos pars mostrarla */
    fetch(Globales.Url + 'Productos/Productos/ObtenerProductosPorCategoriaTiendaArtista', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ idCategoria: idCategoria })
    }
    ).then(res => res.json())
      .then(data => {
        if (data.error) {

        } else {

          var caracteristicasPorTalla = [];
          data.mensaje[0].Producto.map(m => {
            caracteristicasPorTalla.push({ idTalla: m.Tamano, caracteristicasAgregadas: m.caracteristicasAgregadas, atributosAgregados: m.atributosAgregados })

          })
          this.setState({
            CaracteristicasPorTalla: caracteristicasPorTalla

          })

          this.ObtenerTallas(Producto)
        }
      })
  }

  VolverDiseñar=(disenos)=> {
    localStorage.setItem("idModelo", disenos._id)
    localStorage.setItem("idTalla", disenos.idTalla)
    localStorage.setItem("disenioSeleccionado", JSON.stringify(disenos))
    localStorage.setItem("nombreSeleccionado", disenos.nombre)
    localStorage.setItem("tipo", 1)
    localStorage.setItem("desdePerfil","false")
    localStorage.setItem("idModeloSeleccionado",disenos.idModelo)
    window.location.href = "#/diseñador3D"
  }
  
  render() {
    const { classes, ...rest } = this.props;
    const styleParallax = {
      height: "5vh",
      overflow: "hidden"
    }
    const parallaxData = [
      {
        start: 3000,
        end: 3600,
        properties: [
          {
            startValue: 1.2,
            endValue: 0.7,
            property: 'scale',
          },
        ],
      },
    ];
    return (

      // we've set the className to cd-section so we can make smooth scroll to it
      <div className="cd-section" {...rest}>
        <div className={classes.productPage}>
          {/* <div className={classes.relatedProducts}> */}
          <div className={classes.sectionBlank} id="blanksection" />
          <div className={classes.main}>
            <div className={classes.container} style={{maxWidth:'90%'}}>
              <GridContainer md={12} style={{ paddingTop: `3%`,paddingBottom: "20px" }}>
{/*                 <GridItem md={6}>
                  <p style={{ fontSize: `2.5rem`, textAlign: `justify`, lineHeight: `1.5`, color: `#FFD800`, fontWeight: `bold` }}>Envíos nacionales</p>
                  <p style={{ fontSize: `1.5em`, textAlign: `justify`, lineHeight: `1.5`, fontWeight: `bold` }}>Gratis a partir de 3 prendas</p>
                  <p style={{ fontSize: `1.5em`, textAlign: `justify`, lineHeight: `1.5`, fontWeight: `bold` }}>De 3 a 5 días hábiles</p>
                </GridItem>
                <GridItem md={6} className={classes.textCenter}>
                  <p>
                    <img src={box}></img>
                  </p>
                </GridItem> */}
                <GridItem md={3} className={classes.textCenter}>
                  <p>
                    <img src={elige}></img>
                    <h3><b>Elige un <br></br> producto</b></h3>
                    <h5>¡Múltiples productos para adaptar <br></br>tus ideas!</h5>
                  </p>
                </GridItem>
                <GridItem md={3} className={classes.textCenter}>
                  <p>
                    <img src={personaliza}></img>
                    <h3><b>Personaliza</b></h3>
                    <h5>Plasma y visualiza tus ideas<br></br> en la pantalla <br></br>fácilmente</h5>
                  </p>
                </GridItem>
                <GridItem md={3} className={classes.textCenter}>
                  <p>
                    <img src={confirma}></img>
                    <h3><b>Confirma tu <br></br>compra</b></h3>
                    <h5>Realiza tu pago de<br></br> manera segura</h5>
                  </p>
                </GridItem>
                <GridItem md={3} className={classes.textCenter}>
                  <p>
                    <img src={recive}></img>
                    <h3><b>Recíbelo en <br></br>casa</b></h3>
                    <h5>Contamos con envíos a <br></br>toda la república</h5>
                  </p>
                </GridItem>
              </GridContainer>
            </div>
            <div className="container">
              <h2 className={classNames(classes.title, classes.textCenter)} style={{ color: `white` }}>
                Diseños más vendidos:
              </h2>
              <p></p>
              <div className="gridMasVendido">
                {
                  this.state.Disenos.map(diseno =>
                    <div className="item">
                      <a>
                        <img style={{ height: "250px", objectFit: "cover", objectPosition: "center center" }} src={"data:image/png;base64," + diseno.RutaImagen} alt="cardProduct4" />
                        <div class="bg-hover">
                        <div class="info">
                          {global.botonCar ?
                            <div>
                              <Button size="sm" onClick={() => this.AddCarro()} >Agregar a Carrito</Button>
                            </div> : null}
                          <div class="title">
                            {diseno.nombre}
                          </div>
                        </div>
                        </div>
                      </a>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      // </div>
    );


  }
}

export default withStyles(productStyle)(Seccion6);
