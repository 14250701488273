import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import VisorImagenes from './ModalVisorImagenes.jsx'
import Globales from "utils/Globales";
import Cargando from "../Generales/Cargando.jsx";




import style from "assets/jss/material-kit-pro-react/views/componentsSections/contentAreas.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class TablaDetalles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [1, 3, 5],
      productos:[],
      imagenes:[]
    };
    this.eventoModalImagen = this.eventoModalImagen.bind(this)
  }
  componentDidMount(){
    this.ObtenerDetallesProducto();
  }
  ObtenerDetallesProducto(){
    fetch(Globales.Url + 'Productos/Productos/ObtenerAbanicoDeProductosPorModelo', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }, body: JSON.stringify({idModelo:this.props.idModelo})
    })
      .then(res => res.json())
      .then(data => {
      //console.log("TCL: data", data)
        if (data.error) { //Error al obtener los registros
          
        } else { //Obtuvo los registros correctamente
         this.setState({
            productos: data.data
          });
        }
      });
  }
  EnviarImagenesModal(imagenes){
    var Aimagenes=[];
    Aimagenes.push(Globales.Url + imagenes);
    this.setState(
      {
        imagenes:Aimagenes,
      },
      () => {
        this.setState({
          verModalImagenes:true
        });
      })
  }
eventoModalImagen(){
    this.setState({
      verModalImagenes: false
    });
}
CambiarFormatoPrecio(precio){
    var num =
      "$" +
      parseFloat(precio, 10)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
        .toString();
    return num;
  }

  render() {
    const { classes, ...rest } = this.props;
   
    return (
      <div {...rest} className="cd-section" id="contentAreas">
        {
          this.state.productos.length>0 ?
          <div id="tables">
          {
              this.state.verModalImagenes ? <VisorImagenes Imagenes={this.state.imagenes} eventoModal={ this.eventoModalImagen} verModal={this.state.verModalImagenes}/>:null
          }
      
        <GridContainer>
      
          <GridItem
            xs={12}
            sm={10}
            md={12}
            className={`${classes.mrAuto} ${classes.mlAuto}`}
          >
          
            <Table striped tableHead={[
                "Nombre",
                "Atributos",
                "Precio",
                "",
              ]}
              tableData={
                this.state.productos.map(producto =>
                  [
                    producto.NombreProducto,
                    producto.atributosAgregados.map(atributos =>
                    <div>
                      <div style={{float:'left'}} >
                      {atributos.nombre} &nbsp;&nbsp;    
                      </div>
                     <div style={{ float:'left', width: '30px', height: "10px", background: atributos.codigoColor}}></div>  
                     <br/>
                   
                   </div> 
                      ),
                     this.CambiarFormatoPrecio(producto.PrecioPublico),
                    <Button onClick={() => this.EnviarImagenesModal(producto.RutaImagen)} round size='sm' color="github">Ver imagen </Button>
                  ],
                  )
               
              }
              customCellClasses={[
                classes.textCenter,
                classes.padding0,
                classes.textRight,
                classes.textRight
              ]}
              customClassesForCells={[0, 1, 5, 6]}
              customHeadCellClasses={[
                classes.textCenter,
                classes.textRight,
                classes.textRight
              ]}
              customHeadClassesForCells={[0, 5, 6]}
            />
          </GridItem>
          <GridItem>
      
          </GridItem>
        </GridContainer>
      </div> : <Cargando/>

        }
    
       
  
      
      </div>
    );
  }
}

export default withStyles(style)(TablaDetalles);
