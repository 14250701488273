import React, { useState, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import headersStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/headersStyle.jsx";
import Globales from "utils/Globales";
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import 'react-animated-slider/build/slider-animations.css';
import 'react-animated-slider/build/styles.css';
import { useAuth0 } from "@auth0/auth0-react";

function BannerInicio(props) {
  const { classes, dropdownHoverColor } = props;
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [auth, setAuth] = useState(null);
  
  const properties = {
    //duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: true,
    autoplay: 5000
  }
  
  const IniciarSesion = (Referencia) => {
    if(isAuthenticated){
      window.location.href = Referencia;
    }else{
      loginWithRedirect();
    }
  } 

  const content = [
    { image: Globales.Url + 'Seccion1/jersey.jpg', title: 'Personaliza tus Jerseys', description: 'Agrega tu diseño, texto, stickers y mucho más...', button: 'Empieza ya', referencia: '/#/SeleccionaTuDiseño' },
    { image: Globales.Url + 'Seccion1/tshirt.jpg', title: 'Miles de diseños a elegir', description: 'Contamos con librería en línea de más de 2,000 diseños', button: 'Personaliza la tuya', referencia: '/#/SeleccionaTuDiseño' },
    { image: Globales.Url + 'Seccion1/artistas.jpg', title: 'Diseña y vende', description: 'Comecializa tus creaciones únicas y personalizadas a tus clientes', button: 'Diseña la tuya', referencia:  '/#/SeleccionaTuDiseño' }
  ];

  return (
    <div style={{ height: '100vh' }}>
      <Slider {...properties} 
        style={{ height: '100vh',}}
        className="slider-custom"
      >
      {content.map((item, index) => (
        <div key={index}
          className="slider-content"
          style={{ background: `url('${item.image}') no-repeat center center` }} >
          <div className="inner" 
            >
              <h1 style={{ color: `rgb(7, 7, 7)`, textShadow: `rgb(255, 255, 255) 3px 3px` }}>{item.title}</h1>
              <p style={{ color: `rgb(7, 7, 7)`, textShadow: `rgb(255, 255, 255) 3px 3px 3px` }}>{item.description}</p>
              <button onClick={()=>IniciarSesion(item.referencia)}>{item.button}</button>
          </div>
        </div>
      ))}
    </Slider>
    </div>
  );
}

export default withStyles(headersStyle)(BannerInicio);
