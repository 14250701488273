import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react component for creating beautiful carousel
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import styles from "assets/jss/material-kit-pro-react/views/ecommerceSections/latestOffersStyle.jsx";
import Globales from "utils/Globales";
import Logo from "assets/img/terco-img/LogoNook.png";
import Plx from 'react-plx';
import '../../../Styles/GridStyle.css';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import visa from "assets/img/visa.png";
import oxxo from "assets/img/oxxo.png";
import master from "assets/img/master.png";
import spei from "assets/img/spei.png";
import IconYT from "assets/img/RedesSociales/youtube.png";
import IconFB from "assets/img/RedesSociales/facebook.png";
import IconINSTA from "assets/img/RedesSociales/instagram.png";
import IconIN from "assets/img/RedesSociales/linkedIn.png";


class Seccion8 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colorSelect: "0",
      sizeSelect: "0"
    };
  }

  render() {
    const { classes, ...rest } = this.props;
    const parallaxData = [
      {
        start: 4000,
        end: 10000,
        properties: [
          {
            startValue: 10,//Donde inicia
            endValue: -30, //Donde termina
            property: 'translateY',
            unit: '%',
          }
        ]
      }
    ];

    return (
      <div className="cd-section" {...rest}>
        <GridContainer className="container">
{/*           <GridItem className="contenedor" md={2}>
            <a href='/'> <img src={Logo} width="60" height="60" /></a>
          </GridItem> */}
          <GridItem className="contenedor" md={4}>
            <p className="encabezadoFooter">Acerca de nosotros</p>
            <p className="txtFooter"><a>Políticas de envío</a></p>
            <p className="txtFooter"><a>Preguntas frecuentes</a></p>
            <p className="txtFooter">
              <a href="#/TerminosCondiciones">Términos y Condiciones</a>
            </p>
            <p className="txtFooter">
              <a href="#/AvisoPrivacidad">Aviso de Privacidad</a>
            </p>
          </GridItem>
          <GridItem className="contenedor" md={4}>
            <p className="encabezadoFooter">Categorias</p>
            <p className="txtFooter">
              <a href="/">Inicio</a>
            </p>
            <p className="txtFooter">
              <a href="#/SeleccionaTuDiseño">Diseñador</a>
            </p>
            <p className="txtFooter">
              <a href="#/Artistas">Artistas</a>
            </p>
            <p className="txtFooter"><a>Contacto</a></p>
          </GridItem>
          <GridItem className="contenedor" md={4}>
{/*             <div class="input-group">
              <input type="text" class="form-control" placeholder="Ingresa tu correo electrónico" aria-label="Recipient's username" aria-describedby="basic-addon2" />
              <div class="input-group-btn">
                <span className="boton"></span>
              </div>
            </div> */}
            <p className="parrafo">
              En NOOK, somos más que playeras. Somos todo sobre ti y todo lo que te apasiona.<br></br>
            </p>
            <p className="parrafo">
              info@fabricanook.com
            </p>
            <div className="row" md={12} style={{display:'flex',justifyContent: 'center',paddingTop:'40px'}}>
              <div style={{height:'50%'}}> <img src={visa}></img></div>
              <div style={{height:'50%'}}><img src={master}></img></div>
              <div style={{height:'50%'}}><img src={oxxo}></img></div>
              <div style={{height:'50%'}}><img src={spei}></img></div>
            </div>
          </GridItem>
          <GridItem md={4}>
            <div className="sociales"  style={{marginTop:'50px',textAlign:'left !important'}}>
                <a href='/'> <img src={Logo} width="60" height="60" /></a>
            </div>
          </GridItem>
          <GridItem md={4}>
            <div className="sociales"  style={{marginTop:'70px',textAlign:'center'}}>
              <p style={{ color: 'white' }}>© NOOK 2023. Todos los derechos reservados.</p>
            </div>
          </GridItem>
          <GridItem md={4}>
            <div className="sociales"  style={{marginTop:'50px',textAlign:'center'}}>
              <p>
                <span style={{ marginLeft: '10px', marginRight: '10px'}}>
                  <a href="https://www.youtube.com/@fabricanook8346" target="_blank">
                    <img width="35" height="35" src={IconYT} />
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 24 24" fill="none" stroke="#FFD800" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><polygon points="10 8 16 12 10 16 10 8"></polygon></svg> */}
                  </a>
              </span>
                <span style={{marginLeft: '10px', marginRight: '10px'}}>
                <a  href="https://www.facebook.com/FabricaNook" target="_blank">
                <img width="35" height="35" src={IconFB} />
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 320 512">
                    <path fill="#FFD800" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                  </svg> */}
                </a>
              </span>
              {/* <span style={{marginLeft: '10px', marginRight: '10px'}}>
                <a>
                <img width="35" height="35" src={IconIN} />
                  <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 448 512">
                    <path fill="#FFD800" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                  </svg>
                </a>
              </span> */}
              <span style={{marginLeft: '10px', marginRight: '10px'}}>
                <a href="https://www.instagram.com/fabrica_nook?igsh=aXE0N3p2bnl1cWc5" target="_blank">
                <img width="35" height="35" src={IconINSTA} />
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 448 512">
                    <path fill="#FFD800" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                  </svg> */}
                </a>
              </span></p>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(styles)(Seccion8);
