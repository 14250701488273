import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Save, Clear, Publish } from '@material-ui/icons';
import Snackbar from "components/Snackbar/Snackbar.jsx";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
import christian from "assets/img/faces/christian.jpg";
import classNames from "classnames";


// core components
import Button from "components/CustomButtons/Button.jsx";

import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";


import Cookies from 'js-cookie';
import Globales from "utils/Globales";
import UsuarioBlanco from "assets/img/faces/UsuarioBlanco1.png";

import { Upload, message, Icon } from 'antd';
import ModalAnt from 'antd/lib/modal/Modal'
import ButtonAnt from 'antd/lib/button/button'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class ModalAccion extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      id: '',
      Nombre: '',
      Correo: '',
      ConfirmacionContrasena: '',
      TipoUsuario: '',
      CuentaBancaria: '',
      Telefono: '',
      Usuario: '',
      ContrasenaAnterior: '',
      ContrasenaNueva: '',
      mensaje: '',
      ImagenPortada: '',
      FotoPerfil: '',
      Logo: '',
      ListaPortada: [],
      ListaFotoPerfil: [],
      ListaLogo: [],
      ImagenSubida: '',
      FotoSubida: '',
      LogoSubida: '',
      ImagenSubidaListo: false,
      FotoSubidaListo: false,
      LogoSubidaListo:false,
      br: false,
      RutaFotoPerfil: "",
      RutaPortada: "",
      colorNotificacion: 'success',
      errorNombre: false,
      errorCorreo: false,
      errorTelefono: false,
      errorCuentaBancaria: false,
      errorUsuarioLogin: false,
      errorContrasenaAnteriorLogin: false,
      errorUsuario: false,
      errorContrasenaAnterior: false,
      errorConfirmacionContrasena: false,
      errorTipoUsuario: false,
    }
    this.escFunction = this.escFunction.bind(this);
    this.editarPerfil1 = this.editarPerfil1.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeUploadPortada = this.handleChangeUploadPortada.bind(this);
    this.PortadaSubida = this.PortadaSubida.bind(this);
    this.handleChangeUploadFotoPerfil = this.handleChangeUploadFotoPerfil.bind(this);
    this.FotoPerfilSubida = this.FotoPerfilSubida.bind(this);
    this.handleChangeUploadLogo = this.handleChangeUploadLogo.bind(this);
    this.LogoSubida = this.LogoSubida.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.eventClick()
    }
  }

  componentDidMount() {

    document.addEventListener("keydown", this.escFunction, false);
    var usuario = JSON.parse(Cookies.get("UsuarioTerco"))
    //console.log("aa: ", this.props)
    this.setState({
      id: usuario.id,
      TipoUsuario: usuario.TipoUsuario,
      Nombre: usuario.Nombre,
      Correo: usuario.Correo,
      Telefono: usuario.Telefono,
      Usuario: usuario.Usuario,
      ImagenSubida: this.props.Imagenes.Portada,
      FotoSubida: this.props.Imagenes.FotoPerfil,
      LogoSubida: this.props.Imagenes.Logo,
      //Editar foto de perfil

    })
    //console.log("la foto--_",this.props.Imagenes.FotoPerfil)
    this.ObtenerImagenes(usuario.id)
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value })
  }

  handleClose(modal) {
    var x = [];
    x[modal] = false;
    this.setState(x);
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  ObtenerImagenes(id) {
    fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/ObtenerImagenesTiendaArtista', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify({ _id: id })
    }
    ).then(res => res.json())
      .then(data => {
        //console.log("TCL: data", data)
        if (data.error) {

        } else {
          this.setState({
            // ImagenSubida: Globales.Url + "/" + data.data.ImagenPortada,
            // FotoSubida: Globales.Url + "/" + data.data.FotoPerfil,
            // LogoSubida:Globales.Url + "/" + data.data.LogoTienda,
            RutaFotoPerfil: data.data.FotoPerfil == null ? null : data.data.FotoPerfil,
            RutaPortada: data.data.ImagenPortada == null ? null : data.data.ImagenPortada,
            RutaLogo: data.data.LogoTienda == null ? null : data.data.LogoTienda,

          });
        }
      })

  }

  editarPerfil1() {
    fetch(Globales.Url + 'UsuarioClientes/UsuarioClientes/editarPerfil', {
      method: 'POST',
      headers: {
        Accept: 'application/json', 'Content-Type': 'application/json', 'Authorization': Globales.token
      }
      , body: JSON.stringify(this.state)
    }
    ).then(res => res.json())
      .then(data => {
        //console.log("TCL: data", data)
        if (data.error) {
          this.setState({ colorNotificacion: 'danger', mensaje: data.mensaje });
          this.showNotification();

        } else {

          if (this.props.Titulo == "Editar portada") {
            this.setState({ colorNotificacion: 'success', mensaje: "Portada actualizada" });
            this.showNotification();
            this.props.eventClick(false, this.state.ImagenSubida);
          }
          if (this.props.Titulo == "Editar foto de perfil") {
            this.setState({ colorNotificacion: 'success', mensaje: "Foto de perfil actualizada" });
            this.showNotification();
            this.props.eventClick(false, this.state.FotoSubida);
          }
          if (this.props.Titulo == "Editar logo") {
            this.setState({ colorNotificacion: 'success', mensaje: "Logo actualizada" });
            this.showNotification();
            this.props.eventClick(false, this.state.LogoSubida);

          }
        }
      })
      .catch(err => console.error(err));
    //Guardar perfil


  }


  showNotification() {
    var x = [];
    this.setState({ br: true });
    this.setState(x);
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ br: false });
        this.setState(x);
      }.bind(this),
      3000
    );
  }



  handleChangeUploadPortada = ({ fileList }) => {
    this.setState({ ListaPortada: fileList })
  };
  PortadaSubida(res, archivo) {
    /**recorrer el arreglo para cambiar el estado a "done" */

    this.state.ListaPortada.map(m => {
      m.status = "done"

    })
    this.setState({ RutaPortada: "" })
    this.state.ImagenPortada = [];

    const reader = new FileReader();
    reader.readAsDataURL(archivo);
    reader.onload = () => {
      this.state.ImagenPortada = { NombrePortada: archivo.name, base64: reader.result };
      this.setState({
        ImagenSubida: reader.result
      });
    }

  }

  handleChangeUploadFotoPerfil = ({ fileList }) => {
    this.setState({ ListaFotoPerfil: fileList })
  };

  FotoPerfilSubida(res, archivo) {
    /**recorrer el arreglo para cambiar el estado a "done" */

    this.state.ListaFotoPerfil.map(m => {
      m.status = "done"

    })
    this.setState({ RutaFotoPerfil: "" })
    this.state.FotoPerfil = [];

    const reader = new FileReader();
    reader.readAsDataURL(archivo);
    reader.onload = () => {
      this.state.FotoPerfil = { NombreFoto: archivo.name, base64: reader.result };
      this.setState({
        FotoSubida: reader.result
      });
      //console.log("Fopto subida----->",this.state.FotoSubida)
    }

  }



  handleChangeUploadLogo = ({ fileList }) => {
    this.setState({ ListaLogo: fileList })
  };
  LogoSubida(res, archivo) {
    /**recorrer el arreglo para cambiar el estado a "done" */

    this.state.ListaLogo.map(m => {
      m.status = "done"

    })
    this.setState({ RutaLogo: "" })
    this.state.Logo = [];

    const reader = new FileReader();
    reader.readAsDataURL(archivo);
    reader.onload = () => {
      this.state.Logo = { NombreLogo: archivo.name, base64: reader.result };
      this.setState({
        LogoSubida: reader.result
      });
    }

  }








  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.preview,
      previewVisible: true,
    });
  };
  ActualizarImagen() {
  }

  getFiles(event) {

    var origen = event.target.name;
    this.setState({
      [origen+"Listo"]:true
    })

    // var preview = document.getElementById(origen + "1");
    var file = document.getElementById(origen).files[0];
    //console.log("as: ",file);
    
    var reader = new FileReader();

    reader.onloadend = () => {
      var base64 = reader.result;
      if(origen == "FotoSubida"){
        this.setState({ RutaFotoPerfil: "" })
        this.state.FotoPerfil = [];
        this.state.FotoPerfil={
          NombreFoto: file.name, base64: reader.result
        };

      }
      if(origen == "ImagenSubida"){
        this.setState({ RutaPortada: "" })
        this.state.ImagenPortada = [];
        this.state.ImagenPortada = { NombrePortada: file.name, base64: reader.result };

      }
      if(origen == "LogoSubida"){
        this.setState({ RutaLogo: "" })
        this.state.Logo = [];
        this.state.Logo = { NombreLogo: file.name, base64: reader.result };

      }
      //console.log(this.state);
      

     

      this.setState({ 
        [origen]: base64,
        //[origen+"Listo"]:false
      
      })


    }



    if (file) {
      reader.readAsDataURL(file);
    } else {
    }
  }


  render() {
    const { classes, ...rest } = this.props;

    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );

    const { previewVisible, previewImage, ListaPortada, ListaFotoPerfil, ListaLogo } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Agregar</div>
      </div>
    );

    return (
      <div>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal
          }}
          open={this.props.liveDemo}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("liveDemo")}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={() => this.props.eventClick(true, "")}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>
            <h4 className={classes.modalTitle}>{this.props.Titulo}</h4>
          </DialogTitle>
          <DialogContent id="classic-modal-slide-description" className={classes.modalBody}>
            <GridContainer>


              <GridItem lg={12} md={12} sm={12} >
                <center>

                  {
                    this.props.Titulo == "Editar foto de perfil" ?
                      <div>


                        <GridItem lg={12} md={12} sm={12} >
                          <div style={{ fontSize: "15px" }} for="mensaje">
                            Se realizan las siguientes recomendaciones
                            <br /> * Formato cuadrado
                            <br /> * Resolucion 300 x 300 px
                          </div>
                          <br />
                        </GridItem>
                        <GridItem lg={12} md={12} sm={12} >
                          <div id="prueba">
                            <input  id="FotoSubida" name="FotoSubida" className="inputfile" type="file" onChange={this.getFiles.bind(this)} />
                            {/* <div style={{fontSize: "15px"}} for="mensaje">
                              Se realizan las siguientes recomendaciones
                              <br/> * Formato cuadrado
                              <br/> * Resolucion 300 x 300 px
                            </div> */}
                            <label for="FotoSubida">
                                Seleccione imagen
                              {/* { this.state.FotoSubidaListo == true ? "Subiendo" : "Seleccione imagen" } */}
                             </label>
                          </div>
                        </GridItem>
                        <br />



                        {
                          this.state.FotoSubida !== "" && this.state.FotoSubida!=null ?
                            <img style={{ borderRadius: '50%',objectFit: 'cover' }} width="150px" height="150px"   src={this.state.FotoSubida} alt="..." /> : 
                            <img 
                            style={{  
                              borderRadius: '50%', 
                              objectFit: 'cover'
                            }}
                            src={UsuarioBlanco} 
                            width="150px" height="150px"
                          />
                        }




                      </div>
                      : this.props.Titulo == "Editar portada" ?
                        <div>

                          <GridItem lg={12} md={12} sm={12} >
                            <div style={{ fontSize: "15px" }} for="mensaje">
                              Se realizan las siguientes recomendaciones
                              <br /> * Resolucion 1200 x 250 px
                            </div>
                            <br />
                          </GridItem>
                          <GridItem  md={12} sm={12} lg={12}>
                            <div id="prueba">

                              <input id="ImagenSubida" name="ImagenSubida" className="inputfile" type="file" onChange={this.getFiles.bind(this)} />


                              <label for="ImagenSubida">
                                Seleccione imagen
                                {/* { this.state.ImagenSubidaListo == true ? "Subiendo" : "Seleccione imagen" } */}
                              </label>
                            </div>
                          </GridItem>
                          <br />
                          {
                            this.state.ImagenSubida !== "" ?
                              <img style={{aspectRatio: "1200/250", }} width={450} src={this.state.ImagenSubida} /> : null
                          }
                        </div>
                        :

                        <div>

                          <GridItem  md={12} sm={12} lg={12}>
                            <div id="prueba">


                              <input id="LogoSubida" name="LogoSubida" className="inputfile" type="file" onChange={this.getFiles.bind(this)} />


                              <label for="LogoSubida">
                                Seleccione imagen
                                {/* {this.state.LogoSubidaListo == true ? "Subiendo" : "Seleccione imagen"} */}
                              </label>


                              {/* <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                //  listType="picture-card"
                                fileList={ListaLogo}
                                onPreview={this.handlePreview}
                                onChange={this.handleChangeUploadLogo}
                                onSuccess={this.LogoSubida}

                              >
                                {ListaLogo.length >= 1 ? null :
                                  <ButtonAnt><Icon type="upload" /> Seleccione imagen</ButtonAnt>
                                }

                              </Upload> */}
                            </div>
                          </GridItem>
                          <br />

                          {
                            this.state.LogoSubida !== "" ?
                              <img width="100px" height="100px" src={this.state.LogoSubida} /> : null
                          }
                        </div>




                  }







                </center>
              </GridItem>

            </GridContainer>




            {/* <p>{this.props.body}</p> */}
            {/* <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Nombre de Usuario"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    onChange: this.handleChange,
                    value: this.state.Usuario,
                    name: "Usuario",
                    id: "Usuario",
                    type: "text",
                    error: this.state.errorUsuario,
                    disabled: true
                  }} />

              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <FormControl
                  fullWidth
                  className="mt-5"
                >
                  <InputLabel
                    htmlFor="TipoUsuario"
                    className={classes.selectLabel}>
                    Tipo de Usuario
                        </InputLabel>
                  <Select
                    value={this.state.TipoUsuario}
                    onChange={this.handleSimple}
                    name="TipoUsuario"
                    id="TipoUsuario"
                    inputProps={{
                    }}
                  >
                    <MenuItem disabled>Tipo de Usuario</MenuItem>
                    <MenuItem value='Cliente'>Cliente</MenuItem>
                    <MenuItem value='Artista'>Artista</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Nombre Opcional"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    onChange: this.handleChange,
                    value: this.state.Nombre,
                    name: "Nombre",
                    id: "Nombre",
                    type: "text"
                  }} />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Correo Opcional"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    onChange: this.handleChange,
                    value: this.state.Correo,
                    name: "Correo",
                    id: "Correo",
                    type: "email"
                  }} />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Teléfono Opcional"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    onChange: this.handleChange,
                    value: this.state.Telefono,
                    name: "Telefono",
                    id: "Telefono",
                    type: "text"
                  }} />

              </GridItem>
            </GridContainer>
            <div className={classes.space50} />
            <h4 className={classes.modalTitle}>Cambiar Contraseña</h4>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Contraseña Anterior"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    onChange: this.handleChange,
                    value: this.state.ContrasenaAnterior,
                    name: 'ContrasenaAnterior',
                    id: 'ContrasenaAnterior',
                    type: "password",
                    error: this.state.errorContrasenaAnterior
                  }} />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Contraseña Nueva"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    onChange: this.handleChange,
                    value: this.state.ContrasenaNueva,
                    name: "ContrasenaNueva",
                    id: "ContrasenaNueva",
                    type: "password",
                    error: this.state.errorContrasenaNueva
                  }} />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                  labelText="Confirmación de Contraseña"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    required: true,
                    onChange: this.handleChange,
                    value: this.state.ConfirmacionContrasena,
                    name: "ConfirmacionContrasena",
                    id: "ConfirmacionContrasena",
                    type: "password",
                    error: this.state.errorConfirmacionContrasena
                  }} />
              </GridItem>
            </GridContainer> */}
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button size="sm" color="success" className="pull-right" round onClick={this.editarPerfil1.bind(this)}><Save />&nbsp;GUARDAR</Button>
            <Button size="sm" color="danger" className="pull-right" round onClick={() => this.props.eventClick('esc', "")}><Clear />&nbsp;CANCELAR</Button>
          </DialogActions>
        </Dialog>
        <div id="">
          <Snackbar
            place="tr"
            color={this.state.colorNotificacion}
            message={this.state.mensaje}
            open={this.state.br}
            closeNotification={() => this.setState({ br: false })}
            close
          />
        </div>
      </div >
    );
  }
}

export default withStyles(modalStyle)(ModalAccion);