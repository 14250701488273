
// @material-ui/core components

import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import modalStyle from "assets/jss/material-kit-pro-react/modalStyle.jsx";
import Card from "components/Card/Card.jsx";
import Slide from "@material-ui/core/Slide";
import withStyles from "@material-ui/core/styles/withStyles";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";
import TablaDetalles from './TablaDetalles.jsx'

import DialogActions from "@material-ui/core/DialogActions";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

class ModalDetallesProductos extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            checked: [1, 3, 5],
            idModelo3D: '',
            modelosListado: [],
            modelos: [],
            categorias: [],
            colorNotificacion: 'success',
            mensaje: '',
            idCategoria: '',
            cargando: {
                spinnerCategoria: "true",
                spinnerModelo: "false"
            }

        }
        this.escFunction = this.escFunction.bind(this);
    }


    escFunction(event) {
        if (event.keyCode === 27) {
            this.props.eventoModal()
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.escFunction, false);

    }
    componentWillMount() {

    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }
    showNotification() {
        var x = [];
        this.setState({ br: true });
        this.setState(x);
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ br: false });
                this.setState(x);
            }.bind(this),
            3000
        );
    }

    render() {
        const { classes, ...rest } = this.props;
      
      
        return (
         
            <div >


                {/* SIGNUP MODAL START */}
                <Dialog
                    classes={{
                        root: classes.modalRoot,
                        paper: classes.modal + " " + classes.modalSignup
                    }}
                    id="mdlConfiguracionProducto"
                    open={this.props.verModal}
                    TransitionComponent={Transition}
                    keepMounted
              
                    aria-labelledby="signup-modal-slide-title"
                    aria-describedby="signup-modal-slide-description"
                >
                    <Card plain className={classes.modalSignupCard}>
                        <DialogTitle
                            id="signup-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}
                        >
                            <Button
                                simple
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                onClick={() => this.props.eventoModal()}
                            >
                                {" "}
                                <Close className={classes.modalClose} />
                            </Button>
                            <h3
                                className={`${classes.cardTitle} ${
                                    classes.modalTitle
                                    }`}
                            >
                                Productos
                        </h3>
                        </DialogTitle>
                        <DialogContent id="signup-modal-slide-description"  className={classes.modalBody}>
                      
                  
                                    <TablaDetalles idModelo={this.props.idModelo} />
              
                        </DialogContent>
                        <DialogActions className={classes.modalFooter}>
        
          </DialogActions>
                    </Card>
                </Dialog>
                {/* SIGNUP MODAL END */}
            </div >
            
        );
    }
}




export default withStyles(modalStyle)(ModalDetallesProductos);
